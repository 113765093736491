.container {
  margin-top: 5%;
  padding: 8px;
}

.notpicked {
   font-size: 1.5em;
   color: #adadad;
   cursor: pointer
}

.notpicked:hover {
  font-size: 1.5em;
  color: #d2c8ff;
  cursor: pointer
}

.picked {
  font-size: 2.5em;
  color: #F2CC8F;
  cursor: pointer
}

.picked:hover {
  font-size: 2.5em;
  color: #d2c8ff;
  cursor: pointer
}

.largetext {
  font-size: 5em;
  line-height: 1em;
  text-align: center;
}

.mediumtext {
  font-size: 2.5em;
  line-height: 1em;
  text-align: center;
}

.normaltext {
  font-size: 2em;
  line-height: 1em;
  text-align: center;
}

.mapBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 2.5em;
  font-style: italic;
  line-height: 1em;
  margin: 1em;
  color: black;
}

.cuisine {
  font-size: 1.5em;
  line-height: 1em;
  margin: 1em;
  color: black;
}

a:hover {
  color: #d2c8ff;
}

@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ 
  .card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }

  .mapBox {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }

  .mapmap {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
  .card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  .mapBox {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .mapmap {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
  .card {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  .mapBox {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .mapmap {
    position: relative;
    width: 95%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .card {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  .mapBox {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .mapmap {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
  .card {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  .mapBox {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .mapmap {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .card {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  .mapBox {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .mapmap {
    position: relative;
    width: 85%;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.noRest {
  font-size: 2.5em;
  font-style: italic;
  line-height: 0.5em;
  margin: 1em;
  color: white;
}

.sub {
  font-size: 1em;
  color: black;
  line-height: 1em;
}

.subsub {
  font-size: 0.9em;
  color: grey;
  line-height: 1em;
}