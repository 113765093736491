.centreText {
    text-align: center;
}

h1 {
    font-size: 5em;
    margin: 1em;
    line-height: 1em;
}

h3 {
    font-size: 2.5em;
    margin: 1em;
    line-height: 1em;
}

p {
    font-size: 1.5em;
    margin: 1em;
    line-height: 1em;
}

* {
    color: white;
    font-family: Roboto
}